'use client';
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from '@/Redux/Slices/NavMobileSlice/states';

//--------------------------------------------------------------
export const NavMobileSlice = createSlice({
	name: 'NavMobileSlice',
	initialState,
	reducers: {
		SetPage(state, action) {
			state.currentPage = action.payload;
		},
		// Модальное окно о нас
		SetWindowOpen(state, action) {
			state.modalWindAbout = action.payload;
		},
		setAllvote(state, action) {
			state.vote += action.payload;
		},
	},
});

export default NavMobileSlice.reducer;
